<template>
  <div class="page-container_">
    <div class="content-wrapper_">
      <!-- Modal -->
      <div
        class="modal fade"
        id="showNylasBar"
        tabindex="-1"
        aria-labelledby="showNylasBar"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Connect your work calendar
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" style="text-align: center">
              <div class="step3" style="margin-top: 30px">
                <img src="/img/calendar.png" style="width: 120px" />
                <div style="margin-top: 30px" id="nylasLoader2">
                  Calendar sync completed
                </div>

                <div style="margin-top: 10px; color: #aaa; font-size: 11px">
                  reloading your profile
                </div>
              </div>
              <div class="step2">
                <div class="nylasLoader2">
                  <span style="color: rgb(52 54 57)"> Syncing Calendar</span>
                  <img src="/img/grupa-loader.gif" style="height: 120px" />
                </div>
                <!-- <iframe
                  id="nylasAuth"
                  height="600px"
                  width="100%"
                  src=""
                  name="iframe_modal"
                ></iframe> -->
              </div>
              <div class="step1">
                <img src="/img/Calendar.svg" style="margin-top: 35px" />

                <div class="nylasBarText">
                  Connect your calendar to help us schedule meetings quickly
                  with
                  <b>{{ currentUser.user_type == 1 ? "Founder" : "Expert" }}</b>
                  who want to meet you.
                </div>
                <hr />

                <div class="nylasBarTextSub">
                  <!--  Your free/busy schedule is only requested and used when
                  scheduling meetings with founders. -->
                  <code></code>

                  <div class="alert alert-dark" role="alert">
                    Grupa will only use the requested calendar access to view
                    when you're available, and set up conversations with Experts
                    on your behalf.
                    <b
                      >Grupa will not access your calendar or event data for any
                      other purpose.</b
                    >
                  </div>
                  <div class="alert alert-info" role="alert">
                    Browser pop-up access needed to complete the sync process.
                    Kindly allow when prompted!
                  </div>
                </div>

                <br />

                <div class="nylasLoader">
                  <img src="/img/grupa-loader.gif" style="height: 120px" />
                </div>

                <div
                  style="margin: 30px"
                  class="alert alert-danger alert-dismissible nylasAlert"
                  role="alert"
                >
                  <div>An unexpected error occured</div>
                  <code>If issue persist, kindly relogin and try again</code>
                  <button
                    style="font-size: 10px"
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                  ></button>
                </div>

                <div class="nylasBarButton" @click="handleGetNylasUniqueURL">
                  <div style="width: 10%">
                    <img src="/img/google_g_icon.svg" />
                  </div>
                  <div
                    style="
                      align-items: center;
                      text-align: center;
                      width: 90%;
                      color: #53585f;
                    "
                  >
                    Connect with Google
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="showNylasBar() && isHideBar == false"
        class="nylas-warning-container"
        style="display: none"
      >
        <div class="row" style="width: 100%">
          <div class="col-md-8">
            <div class="create-team-msg" style="width: 100%; padding-top: 20px">
              <!--  Setup your profile, invite your friends and get activated to enjoy
              the full Grupa experience. -->

              Complete your profile setup and get activated to enjoy the full
              Grupa experience.
            </div>
          </div>
          <div class="col-md-4 col-sm-12 text-right create-team-msg">
            <button
              class="grupa-blue-btn isSetupProfileClicked"
              data-bs-toggle="modal"
              data-bs-target="#showNylasBar"
              aria-labelledby="staticBackdropLabel"
            >
              Setup Your Profile
            </button>
          </div>
        </div>
      </div>

      <!-- <span
      v-if="showNylasBar() && isHideBar == false"
      v-html="setUpNylasProfile"
    ></span> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { getGrupaNylasRecord, connectGrupaToNylas } from "@/api/nylasApi";

export default {
  props: {},
  components: {},
  data: () => ({
    isGetNylasData: false,
    devNylasRecord: [],
    isHideBar: true,
  }),
  mounted() {
    this.$nextTick(async () => {
      if (!this.isGetStartUpList) {
        // alert("fetchStartUpList");
        // this.fetchStartUpList();
        this.isGetStartUpList = true;
      }

      // this.isGetNylasData = true;

      if (!this.isGetNylasData) {
        this.getGrupaNylasRecordFunction();
        this.isGetNylasData = true;
      }
      $(".step2, .step3, .nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

      let InnterVal = setInterval(() => {
        $(".nylasLoader2").fadeToggle(3000);
      }, 1500);

      //alert(this.showNylasBar())

      let nylas_bar = setTimeout(() => {
        $(".nylas-warning-container").fadeIn(1000);
        clearTimeout(nylas_bar);
      }, 3500);

      $(".isSetupProfileClicked").on("click", function () {
        $(".step2, .step3").fadeOut(() => {
          $(".nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

          $(".step1").fadeIn();
        });
      });

      $(".nylasBarButton_").on("click", async function () {
        const user = JSON.parse(localStorage.getItem("grupa_user"));
        let baseURL = process.env.VUE_APP_BACKEND_API;
        $(".nylasLoader").fadeIn();

        let button = $(this);

        await $.ajax({
          url: `${baseURL}/connect/nylas/nylas_authentication_curl/`,
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
            // xhr.setRequestHeader("contentType", "application/json");
            button.hide();
            //console.log(user.email);
          },

          data: {
            email: user.email,
            //name: "eze okechukwu",
          },
          // data: jQuery.param({ email: user.email }),
          success: function (data) {
            // alert(data);
            $(".nylasLoader").fadeOut(() => {
              // button.hide();
              // $("#nylasAuth").attr("src", data);
            });

            // console.log(data);

            $(".step1").fadeOut(() => {
              $(".step2").fadeIn();
              $(".nylasLoader2").fadeIn();
            });
            //return data;
          },
          error: function (error) {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasLoader2").fadeOut();

            return error;
          },
        });
      });
    });
  },
  methods: {
    handleGetNylasUniqueURL() {
      const user = JSON.parse(localStorage.getItem("grupa_user"));

      let payload = {
        email: user.email,
      };

      let button = $(".nylasBarButton");

      button.fadeOut(0);

      $(".nylasLoader").fadeIn(() => {
        connectGrupaToNylas(payload)
          .then((data) => {
            var nylas_window = window.open(
              data.data,
              "",
              "toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=800,width=657"
            );

            $("#showNylasBar").modal("hide");

            // console.log(nylas_window);

            const timer = setInterval(() => {
              let url = nylas_window.location.href;
              // console.log(url);
              if (nylas_window.closed) {
                clearInterval(timer);
                //
               // clearTimeout(InnterVal);
                //

                //console.log("closed");
              }
            }, 1000);

            //logout-link
            if (this.validURL(data.data)) {
              button.show();
              $(".nylasLoader").fadeOut(() => {
                // button.hide();
                // $("#nylasAuth").attr("src", data.data);

                $(".step1").fadeOut(() => {
                  $(".step2").fadeIn();
                });
              });
            } else {
              button.show();
              $(".nylasLoader").fadeOut();
              $(".nylasAlert").fadeIn();
            }
            //console.log(this.validURL(data.data));
            //console.log(data.data);
          })
          .catch((error) => {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasAlert").fadeIn();
          });
      });

      $(".nylasAlert").fadeOut();
    },
     validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },

    showNylasBar() {
      let state = this.devNylasRecord.hasOwnProperty("user");
      /*  ? (Object.keys(this.devNylasRecord.user).length <= 0
          ? true
          : false)
          : true; */

      if (state) {
        if (this.devNylasRecord.user === null) {
          this.isHideBar = false;
        } else {
          if (Object.keys(this.devNylasRecord.user).length <= 0) {
            this.isHideBar = false;
          } else {
            this.isHideBar = true;
          }
        }
      } else {
        this.isHideBar = false;
      }

      return state;
    },

    async getGrupaNylasRecordFunction() {
      //console.log("this.currentUser", this.currentUser.nylas);
      if (!this.isGetNylasData) {
        let payload = {
          email: this.currentUser.email, //"okechukwu0127@gmail.com", //
        };
        this.isReloadingContent = true;

        //////GET NYLAS RECORD

        this.devNylasRecord = { user: this.currentUser.nylas };
        // console.log(this.devNylasRecord);
        // console.log(JSON.stringify(res.data.data));
        this.isGetNylasData = true;
        this.isReloadingContent = false;

        await getGrupaNylasRecord(payload)
          .then((res) => {
            this.devNylasRecord = res.data.data;
            //  console.log(JSON.stringify(res.data.data));
            this.isGetNylasData = true;
            this.isReloadingContent = false;
          })
          .catch((error) => {
            this.isReloadingContent = true;
            // console.log("ERROR getGrupaNylasRecord: ", error);
          });
      }
    },
  },
  watch: {
    isHideBar: function (change) {
      // console.log({change})
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),
    project() {
      return this.clientProject;
    },
  },
};
</script>

<style scoped>
.nylasBarButton {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
  width: 372px;
  height: 45px;

  /* primary/0 */

  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(27, 30, 34, 0.07),
    1px 2px 4px rgba(27, 30, 34, 0.15);
  border-radius: 3px;
}

.nylasBarText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  color: #1b1e22;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
}

.nylas-warning-container {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border-radius: 4px;
  padding: 0px 62px 0px;
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  text-align: left;
}

.create-team-msg {
  width: 397px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  margin-top: 0px;
  color: #979da5;
  padding: 10px;
}

.content-wrapper {
  text-align: left;
}

.step1 {
  margin: 20px;
}
</style>
